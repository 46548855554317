<template>
  <v-dialog
    v-if="$store.state.user"
    :value="show"
    hide-overlay
    persistent
    width="550"
    transition="dialog-bottom-transition"
  >
    <v-card class="rounded-0">
      <v-card-title class="justify-space-between subtitle-2 align-center font-weight-bold pr-2">
        <div class="d-flex align-cneter">
          <v-icon class="mr-2" color="primary">
            mdi-truck
          </v-icon>
          {{ pickupRequestDetail && parseInt(pickupRequestDetail.id) ? 'Detail Pickup Request' : 'New Pickup Request' }}
        </div>
        <v-icon large class="mr-2" color="red" @click.prevent="$emit('close', true)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider class="mt-1" />
      <v-card-text v-if="pickupRequestDetail" class="pt-4 px-4">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-select
              v-model.number="pickupRequestDetail.cabang"
              :disabled="parseInt(pickupRequestDetail.id) ? true : false"
              :items="$store.state.storeData.filter(r => !parseInt(r.type))"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              class="mb-4"
              label="Pilih Cabang Pengiriman"
            />
            <v-textarea
              v-model="pickupRequestDetail.pickup_address"
              :disabled="parseInt(pickupRequestDetail.id) ? true : false"
              rows="2"
              outlined
              dense
              hide-details
              class="mb-4"
              label="Alamat Lengkap Pickup"
            />
            <v-text-field
              v-model="pickupRequestDetail.notes"
              :disabled="parseInt(pickupRequestDetail.id) ? true : false"
              outlined
              dense
              hide-details
              class="mb-4"
              label="Catatan (jumlah paket, jadwal penjemputan, dll)"
            />
            <!-- <v-divider class="my-4" />
            <v-btn depressed block class="text-capitalize font-weight-bold" color="primary" @click.prevent="PICKUP_PROCESS()">
              Request Pickup
            </v-btn> -->
            <v-divider class="mt-4" />
            <div class="d-flex fill-width align-center justify-space-between pt-4">
              <strong class="secondary--text d-flex align-center">
                <v-icon color="secondary" class="mr-2">
                  mdi-text-box-check-outline
                </v-icon>
                Pickup List {{ pickupData.length ? ('(' + pickupData.length + ') item') : '' }}
              </strong>
              <v-btn outlined class="text-capitalize font-weight-bold" color="primary" small @click.prevent="AddPickupItem()">
                Tambah item
              </v-btn>
            </div>
            <v-divider class="mt-4" />
          </v-col>
          <v-col cols="12" style="height: calc(100vh - 412px);overflow-y: auto;">
            <div v-if="!pickupData.length" class="d-flex fill-width align-center justify-center pa-2 grey--text">
              Belum ada data
            </div>
            <v-card v-for="(m, iM) in pickupData" :key="'pickup-item-list-' + iM" class="pa-4 mb-4 grey lighten-4" outlined style="border-color: #6b6e89;">
              <PickupDetailResi v-model="pickupData[iM]" :product-data="productData" :customer="customerDetail" :error="IsError(m)" />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="pickupRequestDetail" />
      <v-card-actions v-if="pickupRequestDetail" class="py-4">
        <v-btn
          v-if="parseInt(pickupRequestDetail.status) === 1"
          color="red"
          text
          class="rounded-pill text-capitalize px-4"
          @click="PICKUP_PROCESS('reject')"
        >
          Tolak Pickup
        </v-btn>
        <div v-if="parseInt(pickupRequestDetail.status) >= 2" class="font-weight-bold">
          {{ $price(TotalPembayaran) }}
        </div>
        <v-spacer />
        <v-btn
          v-if="parseInt(pickupRequestDetail.status) <= 3"
          color="primary"
          depressed
          class="rounded-pill text-capitalize px-4"
          @click="parseInt(pickupRequestDetail.status) === 1 ? PICKUP_PROCESS('accept') : PICKUP_PROCESS('process')"
        >
          {{ parseInt(pickupRequestDetail.status) === 1 ? 'Konfirmasi Pickup' : (parseInt(pickupRequestDetail.status) === 3 ? 'Konversi menjadi Resi' : 'Proses Pickup') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    productData: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    pickupRequestDetail: null,
    pickupData: [],
    customerDetail: null,
    errorValidation: false,
    PickupPembayaran: null
  }),
  computed: {
    TotalPembayaran () {
      let d = 0
      for (let x = 0; x < this.pickupData.length; x++) {
        d += (parseInt(this.pickupData[x].price) || 0)
      }
      return d
    }
  },
  watch: {
    show (v) {
      if (v) {
        this.pickupData = []
        this.errorValidation = false
        this.customerDetail = null
        this.PickupPembayaran = null
        this.pickupRequestDetail = Object.assign({}, this.detail)
        this.PICKUP_ITEMS_GET()
        if (this.pickupRequestDetail.customer) {
          this.$store.dispatch('customer/GET_ONE', this.pickupRequestDetail.customer)
            .then((res) => {
              if (res.status) {
                this.customerDetail = res.data.data || null
              }
            })
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    IsError (data) {
      if (!this.errorValidation) {
        return { error: false }
      }
      if (!parseInt(data.cabang) < 0) {
        return { error: true, message: 'cabang' }
      } else if (!parseInt(data.cabang_destination) < 0) {
        return { error: true, message: 'cabang_destination' }
      } else if (!parseInt(data.customer)) {
        return { error: true, message: 'customer' }
      } else if (parseInt(data.shipping_origin) < 0) {
        return { error: true, message: 'Asal pengiriman belum ditentukan' }
      } else if (parseInt(data.shipping_destination) < 0) {
        return { error: true, message: 'Tujuan pengiriman belum ditentukan' }
      } else if (!data.shipping_name) {
        return { error: true, message: 'Nama Penerima belum diisi!' }
      } else if (!data.shipping_phone) {
        return { error: true, message: 'No HP Penerima belum diisi!' }
      } else if (!data.shipping_phone) {
        return { error: true, message: 'Alamat Lengkap Belum diisi!' }
      } else if (!data.shipping_sender_name) {
        return { error: true, message: 'Nama pengirim belum diisi!' }
      } else if (!data.shipping_sender_phone) {
        return { error: true, message: 'No HP pengirim belum diisi!' }
      } else if (!parseInt(data.product)) {
        return { error: true, message: 'Produk belum dipilih!' }
      } else if (!parseInt(data.price) && parseInt(data.price_id)) {
        return { error: true, message: 'Harga belum ditentukan!' }
      }
      return { error: false }
    },
    AddPickupItem () {
      this.pickupData.push({
        id_generated: '',
        cabang: parseInt(this.pickupRequestDetail.cabang) || 0,
        cabang_destination: -1,
        vendor: 0,
        vendor_price: 0,
        customer_billto: parseInt(this.pickupRequestDetail.customer) || 0,
        customer: parseInt(this.pickupRequestDetail.customer) || 0,
        sales: 0,
        shipping_origin: parseInt(this.$store.state.user.store_detail.city_id) || 0,
        shipping_destination: 0,
        shipping_name: '',
        shipping_phone: '',
        shipping_address: '',
        shipping_postalcode: '',
        shipping_sender_name: (this.pickupRequestDetail.customer_detail || '').split(',')[0] || '',
        shipping_sender_phone: (this.pickupRequestDetail.customer_detail || '').split(',')[1] || '',
        type: 'KG',
        weight_kg_actual: 0,
        weight_volume_length: 0,
        weight_volume_width: 0,
        weight_volume_height: 0,
        product: 0,
        price_id: 0,
        price: 0,
        price_packaging: 0,
        price_other: 0,
        price_final: 0,
        price_caption: '',
        price_ppn: 1.1,
        price_ppn_value: 0,
        notes: '',
        pickup_id: parseInt(this.pickupRequestDetail.id) || 0,
        pickup: 0,
        pickup_handling: 0,
        pickup_image: null,
        status: 0
      })
    },
    PICKUP_PROCESS (act) {
      const data = Object.assign({}, this.pickupRequestDetail)
      if (parseInt(data.cabang) < 0) {
        this.$store.dispatch('TOAST', { show: true, message: 'Cabang / agen belum dipilih!' })
        return false
      }
      if (!data.pickup_address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Alamat pickup belum lengkap!' })
        return false
      }
      if (!data.notes) {
        this.$store.dispatch('TOAST', { show: true, message: 'Catatan pickup harus diisi!' })
        return false
      }
      data.items = this.pickupData || []
      if (act === 'reject') {
        data.courier = 0
        data.status = 0
      } else if (act === 'accept') {
        data.status = 2
      } else if (act === 'process') {
        if (!data.items.length) {
          this.$store.dispatch('TOAST', { show: true, message: 'Belum ada item!' })
          return false
        }
        this.errorValidation = true
        let isErrorFound = false
        for (let err = 0; err < data.items.length; err++) {
          if (!isErrorFound && this.IsError(data.items[err]).error) {
            isErrorFound = true
          } else {
            const iii = data.items[err]
            const productIndex = this.productData.findIndex(r => parseInt(iii.product) === parseInt(r.id))
            let productDivAmount = 0
            if (productIndex > -1) {
              productDivAmount = parseInt(parseFloat(this.productData[productIndex].div_amount) || 0) || 0
            }
            let kgMetric = this.$VolumeMetric({
              volume_length: parseInt(iii.weight_volume_length) || 0,
              volume_width: parseInt(iii.weight_volume_width) || 0,
              volume_height: parseInt(iii.weight_volume_height) || 0
            }, productDivAmount) || 0
            kgMetric = kgMetric * (parseInt(iii.quantity) || 1)
            const chargeableWeight = parseFloat(iii.weight_kg_actual) > parseFloat(kgMetric) ? iii.weight_kg_actual : kgMetric
            data.items[err].shipping_package = JSON.stringify([{ quantity: 1, volume_length: iii.weight_volume_length, volume_width: iii.weight_volume_width, volume_height: iii.weight_volume_height, kg_metric: kgMetric, kg_actual: iii.weight_kg_actual, chargeable_weight: chargeableWeight }])
          }
        }
        if (isErrorFound) {
          this.$store.dispatch('TOAST', { show: true, message: 'Ops, mohon periksa input!' })
          return false
        }
        if (parseInt(data.status) === 3) {
          data.status = 4
        } else {
          data.status = 3
        }
        data.payment = this.PickupPembayaran
        data.price_total = parseInt(this.TotalPembayaran) || 0
      }
      if (!confirm(act === 'reject' ? 'Tolak Pickup ?' : (act === 'process' ? (parseInt(data.status) === 4 ? 'Konversi menjadi Resi ?' : 'Pastikan data sudah benar ?') : 'Proses Pickup ?'))) {
        return false
      }
      if (this.$store.state.user.role === 'customer') {
        delete data.items
      }
      this.$store.dispatch('logistic/PICKUP_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.$emit('close', true)
            this.pickupData = []
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil diproses!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
          }
        })
    },
    PICKUP_ITEMS_GET () {
      if (!this.pickupRequestDetail || !parseInt(this.pickupRequestDetail.id)) {
        return false
      }
      this.pickupData = []
      this.$store.dispatch('logistic/PICKUP_DETAIL_GET', this.pickupRequestDetail.id)
        .then((res) => {
          if (res.status) {
            this.pickupData = res.data.data.items ? (res.data.data.items.data || []) : []
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Failed!' })
          }
        })
    }
  }
}
</script>

<style>
</style>
